// extracted by mini-css-extract-plugin
export var buttonback = "longformsuccess-module--buttonback--M2h-U";
export var container = "longformsuccess-module--container--fSlHU";
export var containerfluid = "longformsuccess-module--containerfluid--7CQ6B";
export var containersecond = "longformsuccess-module--containersecond--4WQZH";
export var description = "longformsuccess-module--description--hVWS2";
export var descriptionHead = "longformsuccess-module--descriptionHead--1ioxI";
export var head = "longformsuccess-module--head--MM3vw";
export var imgcarta = "longformsuccess-module--imgcarta--K8Omo";
export var maincontainer = "longformsuccess-module--maincontainer--5Gevx";
export var productsbutton = "longformsuccess-module--productsbutton--CGHp+";